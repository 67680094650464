import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Paper
} from '@mui/material';
import ceo from '../assets/images/ceo.jpg';

const AboutUsScreen = () => {
  const company = {
    name: "Apnichhat",
    about: "We are dedicated to connecting clients with top-tier construction firms, and innovative architects and interior designers, all under one roof. With Apni Chhat, you can easily browse profiles, view portfolios, and select the perfect team to execute your vision; we’ve got the right professionals to help you every step of the way. From planning to execution, we ensure that your ideas meet expertise to turn your vision into reality.  From concept to completion, Apni Chhat is your partner in building spaces that inspire.",
    mission: "At Apni Chhat, our mission is to revolutionize the way people build and design spaces by providing a platform that is accessible, efficient, and transparent. We empower homeowners, businesses, and institutions with the tools and access they need to bring their dream spaces to life. Our goal is to make every project — from homes to schools, offices to restaurants — a personalized, stress-free, and successful journey.",
    values: "At Apni Chhat, we value collaboration, quality, transparency, innovation, and customer-centricity, ensuring that every project is a partnership that delivers exceptional, forward-thinking spaces with honesty and precision, all while keeping our clients' needs at the heart of every project.",
  };

  const ceoDetails = {
    name: 'Muhammad Ahmed Siddiq',
    position: 'Founder & CEO',
    image: ceo,
    bio: 'ApniChhat aims to provide its clients with a one-stop solution for transforming their barren land into a loving home, by fostering collaboration with professionals in the field.'
  };

  return (
    <Box sx={{ py: 10, backgroundImage: 'linear-gradient(to right, #01AEFE, #3a6073)', minHeight: '100vh' }}>
      <Container maxWidth="lg">
        <Paper
          elevation={5}
          sx={{
            backgroundColor: '#fff',
            boxShadow: '0 8px 20px rgba(0,0,0,0.15)',
            borderRadius: '16px',
            overflow: 'hidden',
            p: 4,
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 12px 25px rgba(0,0,0,0.2)',
            },
          }}
        >
          {/* Company Introduction */}
          <CardContent>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, color: '#2D3748' }}>
              About {company.name}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.1rem', mb: 4, color: '#555' }}>
              {company.about}
            </Typography>

            {/* Mission & Values */}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}>
                  Our Mission
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                  {company.mission}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#1976d2' }}>
                  Our Values
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                  {company.values}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>

          {/* CEO Section */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4, mb: 3, color: '#1976d2' }}>
            Meet Our CEO
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card
                elevation={4}
                sx={{
                  borderRadius: 2,
                  textAlign: 'center',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                <Avatar
                  src={ceoDetails.image}
                  alt={ceoDetails.name}
                  sx={{
                    width: 120,
                    height: 120,
                    margin: '0 auto',
                    mt: 3,
                    mb: 2,
                    boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                  }}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444' }}>
                    {ceoDetails.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#777' }}>
                    {ceoDetails.position}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#555', mt: 1 }}>
                    {ceoDetails.bio}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default AboutUsScreen;