import React, { useEffect } from "react";
import { Box, Container, Typography, Grid, Card, Avatar, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useDispatch, useSelector } from "react-redux";
import { fetchPortfolios } from "../redux/portfoliosSlice";
import { ImageBaseUrl } from "../Network/constant";

const ProfileListScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const portfolios = useSelector((state) => state.portfolios.portfolios);

    useEffect(() => {
        dispatch(fetchPortfolios()); 
    }, [dispatch]);

    return (
        <Box sx={{ py: 8, backgroundColor: "#f5f5f5", textAlign: "center" }}>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ color: "#333" }}>
                    Available Contractors
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ color: "#555", mb: 4 }}>
                    Browse through our list of professional contractors ready to help with your project.
                </Typography>

                <Grid container spacing={4}>
                    {portfolios.map((portfolio, index) => (
                        <Grid item xs={12} sm={6} md={4} key={portfolio.id}>
                            <Card
                                sx={{
                                    backgroundImage: "linear-gradient(to right, #3a7bd5, #3a6073)",
                                    color: "#fff",
                                    textAlign: "center",
                                    height: "100%",
                                    boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                                    transition: "transform 0.3s",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                    },
                                    position: "relative",
                                }}
                            >
                                <Avatar
                                    src={
                                        portfolio.portfolioImages.length > 0
                                            ? `${ImageBaseUrl}/${portfolio.portfolioImages[0].image}` // Use the first portfolio image if available
                                            : "https://via.placeholder.com/150"
                                    }
                                    alt={portfolio.title}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        margin: "0 auto 16px",
                                        boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                                    }}
                                />
                                <Typography variant="h6" gutterBottom>
                                    {portfolio.company.name}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    {portfolio.title}
                                </Typography>
                                <Typography variant="body2" sx={{ px: 2, pb: 2 }}>
                                    {portfolio.content.substring(0, 100)}... {/* Shortened description */}
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                    <StarIcon sx={{ color: "#FFD700" }} />
                                    <StarIcon sx={{ color: "#FFD700" }} />
                                    <StarIcon sx={{ color: "#FFD700" }} />
                                    <StarIcon sx={{ color: "#FFD700" }} />
                                    <StarBorderIcon sx={{ color: "#FFD700" }} />
                                </Box>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#1976d2",
                                        color: "#fff",
                                        mb: 2,
                                        "&:hover": {
                                            backgroundColor: "#1565c0",
                                        },
                                    }}
                                    onClick={() => navigate("/profileDetailScreen", { state: { portfolio } })}
                                >
                                    View Profile
                                </Button>

                                {/* Social Media Links */}
                                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                    {portfolio.facebookLink && (
                                        <IconButton color="inherit" component="a" href={portfolio.facebookLink} target="_blank">
                                            <FacebookIcon sx={{ color: "#fff" }} />
                                        </IconButton>
                                    )}
                                    {portfolio.linkedinLink && (
                                        <IconButton color="inherit" component="a" href={portfolio.linkedinLink} target="_blank">
                                            <LinkedInIcon sx={{ color: "#fff" }} />
                                        </IconButton>
                                    )}
                                    {portfolio.twitterLink && (
                                        <IconButton color="inherit" component="a" href={portfolio.twitterLink} target="_blank">
                                            <TwitterIcon sx={{ color: "#fff" }} />
                                        </IconButton>
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default ProfileListScreen;