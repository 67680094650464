import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  TextField,
  Button,
  CardContent,
  CardActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories } from "../redux/categoriesSlice";
import { signupUser } from "../redux/authSlice";
import ToastNotification, { showSuccessToast, showErrorToast } from "../components/ToastNotification";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const { loading, error } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    categoryId: "",
    role: "Company",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");

  const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // Password strength checker
    if (e.target.name === "password") {
      const pass = e.target.value;
      if (pass.length >= 8 && /[!@#$%^&*]/.test(pass)) {
        setPasswordStrength("Strong");
      } else if (pass.length >= 6) {
        setPasswordStrength("Medium");
      } else {
        setPasswordStrength("Weak");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match");
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      showErrorToast("Password must be at least 8 characters and include a special character");
      return;
    }

    dispatch(signupUser(formData))
      .unwrap()
      .then(() => {
        showSuccessToast("Account created successfully");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((error) => {
        showErrorToast(error.message);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(135deg, #01AEFE, #42a5f5)",
        padding: "50px 0",
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            background: "#fff",
            borderRadius: "16px",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
            p: 4,
            textAlign: "center",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
            },
          }}
        >
          <CardContent>
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#1976d2", mb: 3 }}>
              Sign Up as a Company
            </Typography>

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Company Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                sx={{ mb: 3 }}
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                type="email"
                sx={{ mb: 3 }}
                required
              />

              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel>Category</InputLabel>
                <Select
                  name="categoryId"
                  value={formData.categoryId}
                  onChange={handleChange}
                  required
                >
                  {categories && categories.length > 0 ? (
                    categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>No categories available</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                sx={{ mb: 3 }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={`Password strength: ${passwordStrength}`}
              />

              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                sx={{ mb: 4 }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowConfirmPassword} edge="end">
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontWeight: "bold",
                  py: 1.5,
                  borderRadius: "8px",
                  fontSize: "1.2rem",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
              >
                Create Account
              </Button>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "center", mt: 2 }}>
            <Typography variant="body2">
              Already have an account?{" "}
              <Button
                sx={{
                  textTransform: "none",
                  color: "#1976d2",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate("/login")}
              >
                Log in here
              </Button>
            </Typography>
          </CardActions>
        </Card>
      </Container>
      <ToastNotification />
    </Box>
  );
};

export default SignupPage;