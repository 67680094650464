import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  Box,
  Avatar,
  Link,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Import social media icons from MUI Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote'; // Using MusicNoteIcon for TikTok

// Import additional icons for Magazine and Publication
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DescriptionIcon from '@mui/icons-material/Description';

import Dashboard from './Dashboard';
import ToastNotification , {showErrorToast , showSuccessToast} from '../components/ToastNotification';
import { fetchPortfolios , deletePortfolio } from '../redux/portfoliosSlice';
import { ImageBaseUrl } from '../Network/constant';


const OverviewPage = () => {
  const dispatch = useDispatch();
  const portfolios = useSelector((state) => state?.portfolios?.portfolios); // All portfolios from the store
  const user = useSelector((state) => state?.auth?.user); // Logged-in user
  const loading = useSelector((state) => state?.portfolios?.loading);
  const error = useSelector((state) => state?.portfolios?.error); // Optional: To handle errors
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPortfolios()); 
  }, [dispatch]);
  console.log(portfolios);
  const userPortfolios = portfolios?.filter((portfolio) => portfolio?.companyId === user?.id);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <Typography variant="h6" color="error">
          Error loading portfolios: {error}
        </Typography>
      </Box>
    );
  }

  const handleEdit = (portfolio) => {
    navigate('/add-portfolio', { state: { portfolio } }); // Pass the portfolio data to AddPortfolio
  };

  return (
    <Dashboard>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
        Portfolio Overview
      </Typography>

      {/* If no portfolios for the user, show a fallback message */}
      {userPortfolios?.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <Typography variant="h6" color="textSecondary">
            No portfolio created yet.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {userPortfolios?.map((portfolio) => (
            <Grid item xs={12} sm={6} md={4} key={portfolio?.id}>
              <Card
                sx={{
                  borderRadius: '16px',
                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                {/* Portfolio Image (first image or placeholder) */}
                <CardMedia
                  component="img"
                  height="200"
                  image={
                    portfolio?.portfolioImages?.length > 0
                      ? `${ImageBaseUrl}/${portfolio?.portfolioImages[0]?.image}`
                      : 'https://via.placeholder.com/300'
                  }
                  alt={portfolio?.title}
                  sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
                />

                {/* Card Content */}
                <CardContent>
                  <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {portfolio?.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    {portfolio?.content}
                  </Typography>

                  <Divider sx={{ my: 1 }} />

                  {/* Social Media Links with Icons */}
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Social Links:
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    {portfolio.facebookLink && (
                      <Link
                        href={portfolio.facebookLink}
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                      >
                        <FacebookIcon sx={{ mr: 1 }} />
                        Facebook
                      </Link>
                    )}
                    {portfolio.twitterLink && (
                      <Link
                        href={portfolio.twitterLink}
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                      >
                        <TwitterIcon sx={{ mr: 1 }} />
                        Twitter
                      </Link>
                    )}
                    {portfolio.linkedinLink && (
                      <Link
                        href={portfolio.linkedinLink}
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                      >
                        <LinkedInIcon sx={{ mr: 1 }} />
                        LinkedIn
                      </Link>
                    )}
                    {portfolio.youtubeLink && (
                      <Link
                        href={portfolio.youtubeLink}
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                      >
                        <YouTubeIcon sx={{ mr: 1 }} />
                        YouTube
                      </Link>
                    )}
                    {portfolio.instagramLink && (
                      <Link
                        href={portfolio.instagramLink}
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                      >
                        <InstagramIcon sx={{ mr: 1 }} />
                        Instagram
                      </Link>
                    )}
                    {portfolio.tiktokLink && (
                      <Link
                        href={portfolio.tiktokLink}
                        target="_blank"
                        sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                      >
                        <MusicNoteIcon sx={{ mr: 1 }} /> {/* Using MusicNoteIcon for TikTok */}
                        TikTok
                      </Link>
                    )}
                  </Box>

                  <Divider sx={{ my: 1 }} />

                  {/* Contact Info */}
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Contact Info:
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Email:</strong> {portfolio.email}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Phone:</strong> {portfolio.phone}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Price:</strong> {portfolio.price}
                  </Typography>

                  {/* Magazine Link */}
                  {portfolio.magzine && (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Magazine:
                      </Typography>
                      {isValidURL(portfolio.magzine) ? (
                        <Link
                          href={portfolio.magzine}
                          target="_blank"
                          sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                        >
                          <LibraryBooksIcon sx={{ mr: 1 }} />
                          Visit Magazine
                        </Link>
                      ) : (
                        <Typography variant="body2">{portfolio.magzine}</Typography>
                      )}
                    </>
                  )}

                  {/* Publication Link */}
                  {portfolio.publication && (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Publication:
                      </Typography>
                      {isValidURL(portfolio.publication) ? (
                        <Link
                          href={portfolio.publication}
                          target="_blank"
                          sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none', color: 'inherit' }}
                        >
                          <DescriptionIcon sx={{ mr: 1 }} />
                          View Publication
                        </Link>
                      ) : (
                        <Typography variant="body2">{portfolio.publication}</Typography>
                      )}
                    </>
                  )}
                </CardContent>

                <Divider sx={{ my: 1 }} />

                {/* Company Details */}
                {/* <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={`${baseURL}/${portfolio.company.image}`}
                      alt={portfolio.company.name}
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {portfolio.company.name} ({portfolio.company.category.name})
                    </Typography>
                  </Box>
                </CardContent> */}

                {/* Actions (Edit / Delete) */}
                <CardActions sx={{ justifyContent: 'flex-end', pr: 2 }}>
                  <IconButton aria-label="edit" sx={{ color: '#1976d2' }} onClick={() => handleEdit(portfolio)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete" sx={{ color: '#d32f2f' }} onClick={() => dispatch(deletePortfolio(portfolio.id)).then(() => showSuccessToast('Portfolio deleted successfully!')).catch(() => showErrorToast('Failed to delete portfolio!'))}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <ToastNotification />
    </Dashboard>
  );
};

// Utility function to validate URLs
const isValidURL = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export default OverviewPage;