import { configureStore } from '@reduxjs/toolkit';
import categoriesReducer from './categoriesSlice';
import authSlice from './authSlice';
import citiesReducer from './citiesSlice';
import portfoliosReducer from './portfoliosSlice';

export const store = configureStore({
    reducer: {
        categories: categoriesReducer,
        auth: authSlice,
        cities: citiesReducer,
        portfolios: portfoliosReducer,
    },
});

export default store;
