import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const costData = {
  Islamabad: {
    "5 Marla": {
      grey_structure_cost: "80 Lakh PKR",
      complete_house_cost: "94.63 Lakh PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "43.75 Lakh PKR",
        bricks_cement_sand_and_crush: "27.15 Lakh PKR",
        roof_insulation_termite_waterproofing: "60.36 Thousand PKR",
        excavation_and_steel_reinforcement: "8.5 Lakh PKR",
        labor_cost: "7.49 Lakh PKR",
        plumbing_works: "6.44 Lakh PKR",
        electric_works: "9.30 Lakh PKR",
        wood_metal_and_tile_works: "30.27 Lakh PKR",
        paint_and_ceiling_works: "5.72 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "56.1 Thousand PKR",
        bathroom: "3.96 Lakh PKR",
        geyser_and_manhole: "63.39 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "48.35 Thousand PKR",
        insulation_and_proofing: "22.14 Thousand PKR",
        drainage_system_piping: "71.57 Thousand PKR",
        gas_piping: "65.85 Thousand PKR",
        pumps_and_drives: "1.12 Lakh PKR",
        ac_drainage_system: "1.39 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "16.41 Thousand PKR",
      },
    },
    "10 Marla": {
      grey_structure_cost: "1.19 Crore PKR",
      complete_house_cost: "1.49 Crore PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "74.60 Lakh PKR",
        bricks_cement_sand_and_crush: "46.95 Lakh PKR",
        roof_insulation_termite_waterproofing: "1 Lakh PKR",
        excavation_and_steel_reinforcement: "11.47 Lakh PKR",
        labor_cost: "12.49 Lakh PKR",
        plumbing_works: "8.73 Lakh PKR",
        electric_works: "13.02 Lakh PKR",
        wood_metal_and_tile_works: "46.66 Lakh PKR",
        paint_and_ceiling_works: "9.55 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "56.1 Thousand PKR",
        bathroom: "4.82 Lakh PKR",
        geyser_and_manhole: "54.26 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "73.37 Thousand PKR",
        insulation_and_proofing: "37.05 Thousand PKR",
        drainage_system_piping: "1.19 Lakh PKR",
        gas_piping: "1.39 Lakh PKR",
        pumps_and_drives: "2.21 Lakh PKR",
        ac_drainage_system: "3.96 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "59.98 Thousand PKR",
      },
    },
    "20 Marla": {
      grey_structure_cost: "2.38 Crore PKR", // Doubled from 1.19 Crore
      complete_house_cost: "2.98 Crore PKR", // Doubled from 1.49 Crore
      direct_cost_breakdown: {
        foundation_and_structure: "1.49 Crore PKR", // Doubled from 74.60 Lakh PKR
        bricks_cement_sand_and_crush: "93.90 Lakh PKR", // Doubled from 46.95 Lakh PKR
        roof_insulation_termite_waterproofing: "2 Lakh PKR", // Doubled from 1 Lakh PKR
        excavation_and_steel_reinforcement: "22.94 Lakh PKR", // Doubled from 11.47 Lakh PKR
        labor_cost: "24.98 Lakh PKR", // Doubled from 12.49 Lakh PKR
        plumbing_works: "17.46 Lakh PKR", // Doubled from 8.73 Lakh PKR
        electric_works: "26.04 Lakh PKR", // Doubled from 13.02 Lakh PKR
        wood_metal_and_tile_works: "93.32 Lakh PKR", // Doubled from 46.66 Lakh PKR
        paint_and_ceiling_works: "19.10 Lakh PKR", // Doubled from 9.55 Lakh PKR
      },
      fittings_and_fixtures: {
        kitchen: "1.12 Lakh PKR", // Doubled from 56.1 Thousand PKR
        bathroom: "9.64 Lakh PKR", // Doubled from 4.82 Lakh PKR
        geyser_and_manhole: "1.08 Lakh PKR", // Doubled from 54.26 Thousand PKR
      },
      utilities_installation: {
        cold_and_hot_water_supply: "1.47 Lakh PKR", // Doubled from 73.37 Thousand PKR
        insulation_and_proofing: "74.10 Thousand PKR", // Doubled from 37.05 Thousand PKR
        drainage_system_piping: "2.38 Lakh PKR", // Doubled from 1.19 Lakh PKR
        gas_piping: "2.78 Lakh PKR", // Doubled from 1.39 Lakh PKR
        pumps_and_drives: "4.42 Lakh PKR", // Doubled from 2.21 Lakh PKR
        ac_drainage_system: "7.92 Lakh PKR", // Doubled from 3.96 Lakh PKR
        upvc_pipelines_for_sewerage_system: "1.20 Lakh PKR", // Doubled from 59.98 Thousand PKR
      },
    },
  },
  Lahore: {
    "5 Marla": {
      grey_structure_cost: "70 Lakh PKR",
      complete_house_cost: "99 Lakh PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "44 Lakh PKR",
        bricks_cement_sand_and_crush: "27 Lakh PKR",
        roof_insulation_termite_waterproofing: "65 Thousand PKR",
        excavation_and_steel_reinforcement: "8 Lakh PKR",
        labor_cost: "7.5 Lakh PKR",
        plumbing_works: "6.5 Lakh PKR",
        electric_works: "9.5 Lakh PKR",
        wood_metal_and_tile_works: "31 Lakh PKR",
        paint_and_ceiling_works: "6 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "60 Thousand PKR",
        bathroom: "4 Lakh PKR",
        geyser_and_manhole: "65 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "50 Thousand PKR",
        insulation_and_proofing: "25 Thousand PKR",
        drainage_system_piping: "70 Thousand PKR",
        gas_piping: "68 Thousand PKR",
        pumps_and_drives: "1 Lakh PKR",
        ac_drainage_system: "1.4 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "18 Thousand PKR",
      },
    },
    "10 Marla": {
      grey_structure_cost: "1.1 Crore PKR",
      complete_house_cost: "1.4 Crore PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "75 Lakh PKR",
        bricks_cement_sand_and_crush: "47 Lakh PKR",
        roof_insulation_termite_waterproofing: "1.1 Lakh PKR",
        excavation_and_steel_reinforcement: "12 Lakh PKR",
        labor_cost: "13 Lakh PKR",
        plumbing_works: "9 Lakh PKR",
        electric_works: "14 Lakh PKR",
        wood_metal_and_tile_works: "47 Lakh PKR",
        paint_and_ceiling_works: "10 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "60 Thousand PKR",
        bathroom: "5 Lakh PKR",
        geyser_and_manhole: "55 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "75 Thousand PKR",
        insulation_and_proofing: "40 Thousand PKR",
        drainage_system_piping: "1.25 Lakh PKR",
        gas_piping: "1.5 Lakh PKR",
        pumps_and_drives: "2.5 Lakh PKR",
        ac_drainage_system: "4 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "65 Thousand PKR",
      },
    },
    "20 Marla": {
      grey_structure_cost: "2.2 Crore PKR", // Doubled from 1.1 Crore
      complete_house_cost: "2.8 Crore PKR", // Doubled from 1.4 Crore
      direct_cost_breakdown: {
        foundation_and_structure: "1.5 Crore PKR", // Doubled from 75 Lakh PKR
        bricks_cement_sand_and_crush: "94 Lakh PKR", // Doubled from 47 Lakh PKR
        roof_insulation_termite_waterproofing: "2.2 Lakh PKR", // Doubled from 1.1 Lakh PKR
        excavation_and_steel_reinforcement: "24 Lakh PKR", // Doubled from 12 Lakh PKR
        labor_cost: "26 Lakh PKR", // Doubled from 13 Lakh PKR
        plumbing_works: "18 Lakh PKR", // Doubled from 9 Lakh PKR
        electric_works: "28 Lakh PKR", // Doubled from 14 Lakh PKR
        wood_metal_and_tile_works: "94 Lakh PKR", // Doubled from 47 Lakh PKR
        paint_and_ceiling_works: "20 Lakh PKR", // Doubled from 10 Lakh PKR
      },
      fittings_and_fixtures: {
        kitchen: "1.2 Lakh PKR", // Doubled from 60 Thousand PKR
        bathroom: "10 Lakh PKR", // Doubled from 5 Lakh PKR
        geyser_and_manhole: "1.1 Lakh PKR", // Doubled from 55 Thousand PKR
      },
      utilities_installation: {
        cold_and_hot_water_supply: "1.5 Lakh PKR", // Doubled from 75 Thousand PKR
        insulation_and_proofing: "80 Thousand PKR", // Doubled from 40 Thousand PKR
        drainage_system_piping: "2.5 Lakh PKR", // Doubled from 1.25 Lakh PKR
        gas_piping: "3 Lakh PKR", // Doubled from 1.5 Lakh PKR
        pumps_and_drives: "5 Lakh PKR", // Doubled from 2.5 Lakh PKR
        ac_drainage_system: "8 Lakh PKR", // Doubled from 4 Lakh PKR
        upvc_pipelines_for_sewerage_system: "1.3 Lakh PKR", // Doubled from 65 Thousand PKR
      },
    },
  },
  Karachi: {
    "5 Marla": {
      grey_structure_cost: "55 Lakh to 60 Lakh PKR",
      complete_house_cost: "75 Lakh to 90 Lakh PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "40 Lakh PKR",
        bricks_cement_sand_and_crush: "25.24 Lakh PKR",
        roof_insulation_termite_waterproofing: "52 Thousand PKR",
        excavation_and_steel_reinforcement: "8.5 Lakh PKR",
        labor_cost: "7 Lakh PKR",
        plumbing_works: "6.74 Lakh PKR",
        electric_works: "8.28 Lakh PKR",
        wood_metal_and_tile_works: "30.27 Lakh PKR",
        paint_and_ceiling_works: "5.5 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "58 Thousand PKR",
        bathroom: "4 Lakh PKR",
        geyser_and_manhole: "64 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "48 Thousand PKR",
        insulation_and_proofing: "30 Thousand PKR",
        drainage_system_piping: "68 Thousand PKR",
        gas_piping: "65 Thousand PKR",
        pumps_and_drives: "1.1 Lakh PKR",
        ac_drainage_system: "1.2 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "17 Thousand PKR",
      },
    },
    "10 Marla": {
      grey_structure_cost: "1.1 Crore to 1.2 Crore PKR",
      complete_house_cost: "1.5 Crore to 1.6 Crore PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "70 Lakh PKR",
        bricks_cement_sand_and_crush: "44.5 Lakh PKR",
        roof_insulation_termite_waterproofing: "95 Thousand PKR",
        excavation_and_steel_reinforcement: "12 Lakh PKR",
        labor_cost: "12 Lakh PKR",
        plumbing_works: "8.5 Lakh PKR",
        electric_works: "13 Lakh PKR",
        wood_metal_and_tile_works: "45 Lakh PKR",
        paint_and_ceiling_works: "9 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "60 Thousand PKR",
        bathroom: "5 Lakh PKR",
        geyser_and_manhole: "65 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "75 Thousand PKR",
        insulation_and_proofing: "45 Thousand PKR",
        drainage_system_piping: "1.1 Lakh PKR",
        gas_piping: "1.4 Lakh PKR",
        pumps_and_drives: "2 Lakh PKR",
        ac_drainage_system: "3.9 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "60 Thousand PKR",
      },
    },
    "20 Marla": {
      grey_structure_cost: "2.1 Crore to 2.3 Crore PKR",
      complete_house_cost: "2.8 Crore to 3 Crore PKR",
      direct_cost_breakdown: {
        foundation_and_structure: "1.5 Crore PKR",
        bricks_cement_sand_and_crush: "91 Lakh PKR",
        roof_insulation_termite_waterproofing: "1.9 Lakh PKR",
        excavation_and_steel_reinforcement: "23 Lakh PKR",
        labor_cost: "20 Lakh PKR",
        plumbing_works: "15 Lakh PKR",
        electric_works: "22 Lakh PKR",
        wood_metal_and_tile_works: "80 Lakh PKR",
        paint_and_ceiling_works: "18 Lakh PKR",
      },
      fittings_and_fixtures: {
        kitchen: "70 Thousand PKR",
        bathroom: "7.5 Lakh PKR",
        geyser_and_manhole: "80 Thousand PKR",
      },
      utilities_installation: {
        cold_and_hot_water_supply: "90 Thousand PKR",
        insulation_and_proofing: "75 Thousand PKR",
        drainage_system_piping: "2.4 Lakh PKR",
        gas_piping: "2.5 Lakh PKR",
        pumps_and_drives: "3 Lakh PKR",
        ac_drainage_system: "5 Lakh PKR",
        upvc_pipelines_for_sewerage_system: "1.2 Lakh PKR",
      },
    },
  },
};

const Calculator = () => {
  const [location, setLocation] = useState("");
  const [marla, setMarla] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [costDetails, setCostDetails] = useState(null);

  const handleCalculate = () => {
    if (location && marla && buildingType) {
      const selectedCostData = costData[location][marla];
      setCostDetails(selectedCostData);
    } else {
      setCostDetails(null);
    }
  };

  // Prepare data for Pie Chart
  const chartData = costDetails
    ? {
        labels: [
          ...Object.keys(costDetails.direct_cost_breakdown),
          ...Object.keys(costDetails.fittings_and_fixtures),
          ...Object.keys(costDetails.utilities_installation),
        ],
        datasets: [
          {
            label: "Cost Breakdown",
            data: [
              ...Object.values(costDetails.direct_cost_breakdown).map((val) =>
                parseFloat(val.replace(/[^0-9.]/g, ""))
              ),
              ...Object.values(costDetails.fittings_and_fixtures).map((val) =>
                parseFloat(val.replace(/[^0-9.]/g, ""))
              ),
              ...Object.values(costDetails.utilities_installation).map((val) =>
                parseFloat(val.replace(/[^0-9.]/g, ""))
              ),
            ],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
            ],
            hoverOffset: 4,
          },
        ],
      }
    : null;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(135deg, #01AEFE, #42a5f5)",
        padding: "50px 0",
      }}
    >
      <Container maxWidth="lg">
        <Card
          sx={{
            background: "#fff",
            borderRadius: "16px",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
            p: 4,
            textAlign: "center",
            transition: "transform 0.3s",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#1976d2", mb: 3 }}
            >
              House Cost Calculator
            </Typography>

            <Grid container spacing={3}>
              {/* Select Location */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    label="Location"
                  >
                    <MenuItem value="Islamabad">Islamabad</MenuItem>
                    <MenuItem value="Lahore">Lahore</MenuItem>
                    <MenuItem value="Karachi">Karachi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Select Marla */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Marla Size</InputLabel>
                  <Select
                    value={marla}
                    onChange={(e) => setMarla(e.target.value)}
                    label="Marla Size"
                    disabled={!location}
                  >
                    {location &&
                      Object.keys(costData[location]).map((size) => (
                        <MenuItem key={size} value={size}>
                          {size}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Select Building Type */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Building Type</InputLabel>
                  <Select
                    value={buildingType}
                    onChange={(e) => setBuildingType(e.target.value)}
                    label="Building Type"
                    disabled={!marla}
                  >
                    <MenuItem value="Grey Structure">Grey Structure</MenuItem>
                    <MenuItem value="Complete House">Complete House</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Calculate Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ py: 1.5, borderRadius: "8px" }}
                  onClick={handleCalculate}
                  disabled={!buildingType}
                >
                  Calculate
                </Button>
              </Grid>
            </Grid>

            {/* Display Cost Details */}
            {costDetails && (
              <>
                <Box sx={{ mt: 4 }}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}
                  >
                    Cost Breakdown
                  </Typography>

                  {/* Show Grey Structure or Complete House Cost */}
                  <Paper sx={{ mb: 4, p: 2 }}>
                    <Typography variant="body1">
                      <strong>Total {buildingType} Cost:</strong>{" "}
                      {buildingType === "Grey Structure"
                        ? costDetails.grey_structure_cost
                        : costDetails.complete_house_cost}
                    </Typography>
                  </Paper>

                  {/* Direct Cost Breakdown */}
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}
                  >
                    Direct Cost Breakdown
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Item</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>Cost</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(costDetails.direct_cost_breakdown).map(
                        ([item, cost]) => (
                          <TableRow key={item}>
                            <TableCell>{item.split("_").join(" ")}</TableCell>
                            <TableCell align="right">{cost}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>

                  {/* Fittings and Fixtures */}
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", mt: 4, mb: 2, color: "#1976d2" }}
                  >
                    Fittings and Fixtures
                  </Typography>
                  <Table>
                    <TableBody>
                      {Object.entries(costDetails.fittings_and_fixtures).map(
                        ([item, cost]) => (
                          <TableRow key={item}>
                            <TableCell>{item.split("_").join(" ")}</TableCell>
                            <TableCell align="right">{cost}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>

                  {/* Utilities Installation */}
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", mt: 4, mb: 2, color: "#1976d2" }}
                  >
                    Utilities Installation
                  </Typography>
                  <Table>
                    <TableBody>
                      {Object.entries(costDetails.utilities_installation).map(
                        ([item, cost]) => (
                          <TableRow key={item}>
                            <TableCell>{item.split("_").join(" ")}</TableCell>
                            <TableCell align="right">{cost}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Box>

                {/* Pie Chart */}
                <Box sx={{ mt: 4 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#1976d2", mb: 2 }}
                  >
                    Cost Breakdown
                  </Typography>
                  {chartData && <Pie data={chartData} />}
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Calculator;