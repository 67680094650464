// citiesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../Network/NetworkManager";

// Async thunk for fetching cities
export const fetchCities = createAsyncThunk(
  "cities/fetchCities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/cities");
      return response.data.data; // Return the fetched cities data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle any errors
    }
  }
);

// Cities slice
const citiesSlice = createSlice({
  name: "cities",
  initialState: {
    cities: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // When fetching cities is pending
      .addCase(fetchCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // When fetching cities is successful
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload; // Save the fetched cities data to the state
      })
      // When fetching cities fails
      .addCase(fetchCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Save the error to the state
      });
  },
});

// Export the cities slice reducer
export default citiesSlice.reducer;
