import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Divider
} from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f9f9f9',
        py: 8,
      }}
    >
      <Container maxWidth="md">
        <Card sx={{ boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)', borderRadius: '16px' }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#1976d2' }}>
              Privacy Policy
            </Typography>

            <Typography variant="body1" sx={{ mb: 4 }}>
              Welcome to Apni Chhat, an online marketplace connecting architects, construction companies, and interior designers with customers throughout Pakistan. We are committed to protecting the privacy of all our users and ensuring the security of their personal information. This policy outlines how we collect, use, store, and disclose your data when you use our website and app. By using Apni Chhat, you agree to this Privacy Policy.
            </Typography>

            <Divider sx={{ my: 4 }} />

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              1. Information We Collect
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              When you create an account or portfolio on Apni Chhat, we collect personal and professional information that may include, but is not limited to:
              <ul>
                <li>Company/Individual Name</li>
                <li>Contact Details (email address, phone number, address)</li>
                <li>Company Logo, Portfolio Images, and Work History</li>
                <li>Social Media Links and Website Information</li>
                <li>Payment Information for Subscription Services</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              2. How We Use Your Information
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              We use the information we collect to:
              <ul>
                <li>Provide and improve our services.</li>
                <li>Facilitate communication between customers and companies.</li>
                <li>Process subscription payments via our third-party payment partner, UBL (United Bank Limited).</li>
                <li>Offer support and respond to inquiries.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              3. Information Security
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              We take the security of your personal information seriously. Apni Chhat uses industry-standard measures to protect your data, including secure encryption and storage practices. However, no data transmission over the internet or electronic storage method can be guaranteed to be 100% secure, and we cannot warrant absolute security.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              4. Third-Party Payment Processing
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Apni Chhat uses UBL (United Bank Limited) for all subscription payments. By subscribing to premium services, you agree to share necessary payment information with UBL. Apni Chhat does not store or process any payment card details directly. Please review UBL's privacy policy for more information on their data practices.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              5. User-Generated Content
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Apni Chhat serves as a platform for architects, construction companies, and interior designers to showcase their work. Users (both companies and professionals) are responsible for the accuracy, legality, and ownership of the information and images they upload, including any portfolio content. Apni Chhat does not claim ownership of any content posted by users and is not liable for any inaccuracies or disputes arising from such content.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              6. Disclaimer on Posted Content
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Apni Chhat is not responsible for the quality, accuracy, or reliability of the information, images, and work posted by architects, construction companies, or interior designers. All content on a user’s portfolio is the sole responsibility of the user who uploaded it. Apni Chhat does not verify, endorse, or guarantee the content provided by users.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              7. Limitation of Liability
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Apni Chhat acts solely as a marketplace to facilitate connections between companies and customers. We are not responsible for any agreements, contracts, or disputes that may arise between users of our platform. Users agree to indemnify and hold Apni Chhat harmless from any claims related to their activities on the platform.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              8. Sharing and Disclosure of Information
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              We do not sell, trade, or rent your personal information to third parties. However, we may share information with:
              <ul>
                <li>Service providers who assist in operating our platform.</li>
                <li>Law enforcement or government authorities where required by law.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              9. Your Rights
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              You have the right to:
              <ul>
                <li>Access and update your personal information.</li>
                <li>Request deletion of your account and personal data.</li>
                <li>Opt-out of marketing communications.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              10. Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Apni Chhat reserves the right to update this privacy policy at any time. Changes will be posted on this page, and it is the responsibility of users to review the policy periodically.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              11. Contact Information
            </Typography>
            <Typography variant="body1">
              If you have any questions or concerns about this Privacy Policy, please contact us at info@apnichhat.com.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
