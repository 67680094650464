
import axios from 'axios';
const api = axios.create({
  baseURL: 'https://api-dev.apnichhat.pk',
});

api.interceptors.request.use(
  (config) => {
    // Get the token from localStorage (or other storage)
    const token = localStorage.getItem('token');

    // If token exists, add it to the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

export default api;
