import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Divider
} from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f9f9f9',
        py: 8,
      }}
    >
      <Container maxWidth="md">
        <Card sx={{ boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)', borderRadius: '16px' }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4, color: '#1976d2' }}>
              Terms and Conditions
            </Typography>

            <Typography variant="body1" sx={{ mb: 4 }}>
              Welcome to "Apni Chhat." By using our website or app, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully. If you do not agree with these terms, you should not use the services.
            </Typography>

            <Divider sx={{ my: 4 }} />

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              1. Introduction
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Welcome to "Apni Chhat." By using our website or app, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully. If you do not agree with these terms, you should not use the services.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              2. Definitions
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <strong>“We”</strong> or <strong>“Us”</strong> or <strong>“Our”</strong> refers to Apni Chhat. <br />
              <strong>“You”</strong> or <strong>“User”</strong> refers to anyone using the services provided by the Apni Chhat website or app. <br />
              <strong>“Service”</strong> refers to the marketplace platform connecting customers to construction companies, architects, and interior designers.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              3. User Accounts
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <ul>
                <li><strong>Registration</strong>: Users must register for an account to use certain features of the service.</li>
                <li><strong>Account Security</strong>: You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities under your account.</li>
                <li><strong>Account Termination</strong>: We reserve the right to terminate or suspend accounts that violate these terms.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              4. Use of Services
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <ul>
                <li><strong>Service Availability</strong>: We strive to provide the best possible experience, but there may be occasional downtimes for maintenance or technical issues.</li>
                <li><strong>Prohibited Activities</strong>: Users are prohibited from misusing the service, including but not limited to posting false information, engaging in fraud, or violating intellectual property rights.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              5. Subscription and Payment
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <ul>
                <li><strong>Subscription Model</strong>: Our services are provided on a subscription basis with three different subscription tiers.</li>
                <li><strong>Payment</strong>: Payments for subscriptions are processed through UBL (United Bank Limited).</li>
                <li><strong>Refund Policy</strong>: No refunds will be issued once payment is processed, except in cases of double billing or technical errors.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              6. Content
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <ul>
                <li><strong>User-Generated Content</strong>: Users may upload profiles, images, reviews, and other content. You are responsible for ensuring that your content complies with all applicable laws and does not infringe on third-party rights.</li>
                <li><strong>Content Moderation</strong>: We reserve the right to remove or modify any content that violates these terms or is deemed inappropriate.</li>
                <li><strong>License to Use Content</strong>: By submitting content, you grant us a non-exclusive, royalty-free license to display and promote the content within the platform.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              7. Privacy Policy
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Your use of the service is also governed by our Privacy Policy, which outlines how we collect, use, and protect your data.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              8. Intellectual Property
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <ul>
                <li><strong>Ownership</strong>: All intellectual property rights related to the website, app, and services remain with Apni Chhat.</li>
                <li><strong>License to Use</strong>: Users are granted a limited, non-exclusive, revocable license to use the service for personal or commercial purposes in accordance with these terms.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              9. Liability and Indemnity
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <ul>
                <li><strong>Limitation of Liability</strong>: Apni Chhat shall not be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the service.</li>
                <li><strong>Indemnity</strong>: Users agree to indemnify and hold harmless Apni Chhat from any claims, damages, or expenses arising out of the user’s violation of these terms or applicable laws.</li>
              </ul>
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              10. Changes to the Terms
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              We reserve the right to update or modify these terms at any time. Any changes will be effective immediately upon posting on the website or app. It is your responsibility to review these terms periodically.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              11. Governing Law
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              These terms are governed by and construed in accordance with the laws of Pakistan, and any disputes will be subject to the exclusive jurisdiction of the courts of Pakistan.
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              12. Contact Information
            </Typography>
            <Typography variant="body1">
              For any questions or concerns about these terms, please contact us at:
              <ul>
                <li><strong>Email</strong>: info@apnichhat.com</li>
                <li><strong>Phone</strong>: 0337-7068547</li>
                <li><strong>Address</strong>: Office No. 4, Block No. 18, PHA Apartments, G-7/1, Islamabad (44000)</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;