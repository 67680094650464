import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Avatar,
  Button,
  Card,
  TextField,
  IconButton,
  Divider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import Dashboard from './Dashboard';
import { updateUserProfile } from '../redux/authSlice';
import ToastNotification, { showSuccessToast, showErrorToast } from "../components/ToastNotification";
import { ImageBaseUrl } from '../Network/constant';

const SettingsScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user); 
  console.log('User Data---->', user);
  const [formData, setFormData] = useState({
    name: user?.name || user?.data?.name || '',
    email: user?.email || user?.data?.email || '',
    profileImage: null, 
  });

  console.log('formData Data---->', formData);


  console.log('User Data---->', user);
  // Handle change for input fields
  const handleChange = (e) => {
    if (e.target.name === 'profileImage') {
      setFormData({
        ...formData,
        profileImage: e.target.files[0], // Handle image file
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create form data to send to the server (for file uploads)
    const updatedData = new FormData();
    updatedData.append('name', formData.name);
    updatedData.append('email', formData.email);
    if (formData.profileImage) {
      updatedData.append('image', formData.profileImage); // Only append if there is a new image
    }

    // Dispatch updateUserProfile thunk
    dispatch(updateUserProfile({ formData: updatedData })) // You might need to pass a user ID, adjust as per your API design.
      .unwrap()
      .then(() => {
        showSuccessToast('Profile updated successfully');
      })
      .catch((error) => {
        showErrorToast('Failed to update profile');
      });
  };


  return (
    <Dashboard>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Card sx={{ p: 3, borderRadius: '16px', boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)' }}>
          <form onSubmit={handleSubmit}>
            {/* User Profile */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Avatar
                  src={`${ImageBaseUrl}/${user?.data?.image}`}
                  alt={user?.name}
                  sx={{ width: 120, height: 120, border: '2px solid #f0f0f0' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {user?.name || 'Ali'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                  <Button variant="outlined" startIcon={<UploadIcon />} component="label" sx={{ mr: 1, mb: 1 }}>
                    Upload
                    <input
                      type="file"
                      name="profileImage"
                      accept="image/*"
                      hidden
                      onChange={handleChange}
                    />
                  </Button>
                  <IconButton color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Editable Name Field */}
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={9}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Name
                </Typography>
                <TextField
                  name="name"
                  value={formData.name || user?.name || ''}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Editable Email Field */}
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={9}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Email
                </Typography>
                <TextField
                  name="email"
                  value={formData.email || user?.email || ''}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  type="email"
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 3,
                backgroundColor: "#1976d2",
                color: "#fff",
                fontWeight: "bold",
                py: 1.5,
                borderRadius: "8px",
                fontSize: "1.2rem",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Update Profile
            </Button>
          </form>
        </Card>
      </Container>
      <ToastNotification />
    </Dashboard>
  );
};

export default SettingsScreen;