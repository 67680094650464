import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Network/NetworkManager';

// Async thunk for fetching portfolios
export const fetchPortfolios = createAsyncThunk(
  'portfolios/fetchPortfolios',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/portfolios');
      return response.data.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for creating a new portfolio
export const createPortfolio = createAsyncThunk(
  'portfolios/createPortfolio',
  async (portfolioData, { rejectWithValue }) => {
    try {
      const response = await api.post('/portfolios', portfolioData);
      return response.data; // Assuming the response contains the created portfolio
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for updating a portfolio
export const updatePortfolio = createAsyncThunk(
  'portfolios/updatePortfolio',
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/portfolios/${id}`, updatedData);
      return { id, updatedData: response.data }; // Return the updated portfolio
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for deleting a portfolio
export const deletePortfolio = createAsyncThunk(
  'portfolios/deletePortfolio',
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/portfolios/${id}`);
      return id; // Return the ID of the deleted portfolio
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Portfolios slice
const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState: {
    portfolios: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch portfolios
      .addCase(fetchPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        state.portfolios = action.payload; // Assuming response is an array of portfolios
      })
      .addCase(fetchPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create portfolio
      .addCase(createPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.portfolios.push(action.payload); // Add the newly created portfolio to the state
      })
      .addCase(createPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update portfolio
      .addCase(updatePortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePortfolio.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.portfolios.findIndex(portfolio => portfolio.id === action.payload.id);
        if (index !== -1) {
          state.portfolios[index] = { ...state.portfolios[index], ...action.payload.updatedData };
        }
      })
      .addCase(updatePortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete portfolio
      .addCase(deletePortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.portfolios = state.portfolios.filter(portfolio => portfolio.id !== action.payload);
      })
      .addCase(deletePortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default portfoliosSlice.reducer;