import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.auth.user); // Get user from Redux store

  if (!user) {
    // If no user is authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If user is authenticated, render the children components (protected routes)
  return children;
};

export default PrivateRoute;
