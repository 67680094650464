import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Link,
} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import Slider from "react-slick"; // Import the slider component
import { useLocation } from "react-router-dom";
import { ImageBaseUrl } from "../Network/constant"; // Assuming this is where you get image base URL

const PortfolioDetail = () => {
  const location = useLocation();
  const { portfolio } = location?.state || {}; // Get portfolio data from state

  // Slider settings for horizontal scrolling
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 images at a time
    slidesToScroll: 1, // Scroll one image at a time
    autoplay: true,
    autoplaySpeed: 2000, // Automatically scroll every 2 seconds
    arrows: true, // Enable arrows for manual scrolling
  };

  return (
    <Box
      sx={{
        py: 10,
        backgroundImage: 'linear-gradient(to right, #3a7bd5, #3a6073)',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Card
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0 8px 20px rgba(0,0,0,0.15)",
            borderRadius: "16px",
            overflow: "hidden",
            p: 4,
            transition: "transform 0.3s, box-shadow 0.3s",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 12px 25px rgba(0,0,0,0.2)",
            },
          }}
        >
          <CardContent>
            {/* Portfolio Images Slider */}
            <Slider {...settings}>
              {portfolio.portfolioImages.map((imageObj, index) => (
                <Box key={index} sx={{ px: 1 }}>
                  <img
                    src={`${ImageBaseUrl}/${imageObj.image}`}
                    alt={`Portfolio Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              ))}
            </Slider>

            {/* Header */}
            <Typography variant="h4" sx={{ fontWeight: "bold", mt: 4, mb: 3, color: "#2D3748" }}>
              {portfolio.title}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.1rem", mb: 4, color: "#555" }}>
              {portfolio.content}
            </Typography>

            {/* Company Information */}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}>
                  Company Information
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText primary="Price" secondary={`$${portfolio.price}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Phone" secondary={portfolio.phone} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Email" secondary={portfolio.email} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Website" secondary={
                      <Link
                        href={portfolio.website}
                        target="_blank"
                        sx={{
                          color: "#1976d2",
                          textDecoration: 'none',
                          "&:hover": { textDecoration: 'underline' }
                        }}
                      >
                        {portfolio.website}
                      </Link>
                    } />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="publication"  secondary={
                      <Link
                        href={portfolio.publication}
                        target="_blank"
                        sx={{
                          color: "#1976d2",
                          textDecoration: 'none',
                          "&:hover": { textDecoration: 'underline' }
                        }}
                      >
                        {portfolio.publication}
                      </Link>
                    } />
                  </ListItem>
                </List>
              </Grid>

              {/* Social Links */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, color: "#1976d2" }}>
                  Connect with Us
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  {portfolio.facebookLink && (
                    <IconButton href={portfolio.facebookLink} target="_blank">
                      <FacebookIcon fontSize="large" sx={{ color: "#4267B2" }} />
                    </IconButton>
                  )}
                  {portfolio.twitterLink && (
                    <IconButton href={portfolio.twitterLink} target="_blank">
                      <TwitterIcon fontSize="large" sx={{ color: "#1DA1F2" }} />
                    </IconButton>
                  )}
                  {portfolio.linkedinLink && (
                    <IconButton href={portfolio.linkedinLink} target="_blank">
                      <LinkedInIcon fontSize="large" sx={{ color: "#0077b5" }} />
                    </IconButton>
                  )}
                  {portfolio.youtubeLink && (
                    <IconButton href={portfolio.youtubeLink} target="_blank">
                      <YouTubeIcon fontSize="large" sx={{ color: "#FF0000" }} />
                    </IconButton>
                  )}
                  {portfolio.instagramLink && (
                    <IconButton href={portfolio.instagramLink} target="_blank">
                      <InstagramIcon fontSize="large" sx={{ color: "#C13584" }} />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* Additional Info */}
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 4, mb: 2, color: "#1976d2" }}>
              Additional Information
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ color: "#555" }}>
                  <strong>Publication:</strong> {portfolio.publication}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default PortfolioDetail;