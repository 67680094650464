import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import Navbar from "./components/Navbar";
import HomeScreen from "./screens/HomeScreen";
import ProfileListScreen from "./screens/ProfileListScreen";
import ProfileDetailScreen from "./screens/ProfileDetailScreen";
import SignupPage from "./screens/SignupPage";
import LoginPage from "./screens/LoginPage";
import PortfolioDetail from "./screens/PortfolioDetail";
import OverviewPage from "./screens/OverviewPage";
import AddPortfolio from "./screens/AddPortfolio";
import SettingsScreen from "./screens/SettingsScreen";
import Footer from "./components/Footer";
import PrivateRoute from "./PrivateRoute";
import AboutScreen from "./screens/aboutScreen";
import Calculator from "./screens/Calculator";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";

function Layout() {
  const location = useLocation();

  // Define the paths where you don't want to show Navbar and Footer
  const hideNavbarFooter =
    location.pathname === "/signup" ||
    location.pathname === "/login" ||
    location.pathname === "/overview" ||
    location.pathname === "/add-portfolio" ||
    location.pathname === "/settings";

  return (
    <>
      <Provider store={store}>
        {!hideNavbarFooter && <Navbar />}
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/contractors" element={<ProfileListScreen />} />
          <Route
            path="/profileDetailScreen"
            element={<ProfileDetailScreen />}
          />
          <Route path="/portfolioDetail" element={<PortfolioDetail />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="/overview"
            element={
              <PrivateRoute>
                <OverviewPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-portfolio"
            element={
              <PrivateRoute>
                <AddPortfolio />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <SettingsScreen />
              </PrivateRoute>
            }
          />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
        {!hideNavbarFooter && <Footer />}
      </Provider>
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
