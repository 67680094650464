import React, { useEffect } from 'react';
import { Box, Container, Typography, Grid, Avatar, Paper, Card, CardMedia, CardContent, CardActionArea } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolios } from "../redux/portfoliosSlice";
import { ImageBaseUrl } from "../Network/constant";

const ProfileDetailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { portfolio } = location?.state || {}; 
  const dispatch = useDispatch();
  const portfolios = useSelector((state) => state.portfolios.portfolios);

  // Fetch portfolios on mount
  useEffect(() => {
    dispatch(fetchPortfolios());
  }, [dispatch]);

  // Filter portfolios that belong to the same company as the selected portfolio
  const companyPortfolios = portfolios.filter(p => p.companyId === portfolio.companyId);

  const getDollarSigns = (price) => {
    if (price ==='100-120') return '$';
    if (price === '120-250') return '$$';
    if (price == '250-above') return '$$$';
    return '';
  };

  return (
    <Box sx={{ py: 8, backgroundColor: "#f9f9f9" }}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* Profile Section */}
          <Grid item xs={12} md={4}>
            <Paper 
              elevation={5} 
              sx={{ 
                padding: 4, 
                borderRadius: 2, 
                backgroundImage: 'linear-gradient(to right, #4e54c8, #8f94fb)', 
                color: '#fff' 
              }}>
              <Box sx={{ textAlign: 'center' }}>
                <Avatar
                  src={`${ImageBaseUrl}/${portfolio.company.image}`}
                  alt={portfolio.company.name}
                  sx={{ 
                    width: 120, 
                    height: 120, 
                    margin: '0 auto 16px', 
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' 
                  }}
                />
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {portfolio.company.name}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
                  {portfolio.company.category.name}
                </Typography>
                <Typography variant="body2">
                  {portfolio.company.email}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Portfolio Section */}
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom sx={{ color: "#333", fontWeight: 'bold' }}>
              Portfolio
            </Typography>
            <Grid container spacing={4}>
              {companyPortfolios?.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card 
                    elevation={4} 
                    sx={{ 
                      borderRadius: 2, 
                      transition: 'transform 0.3s, box-shadow 0.3s', 
                      '&:hover': { 
                        transform: 'scale(1.05)', 
                        boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.15)' 
                      } 
                    }}>
                    <CardActionArea onClick={() => navigate("/portfolioDetail", { state: { portfolio: item } })}>
                      <CardMedia
                        component="img"
                        height="160"
                        image={item.portfolioImages.length > 0 ? `${ImageBaseUrl}/${item.portfolioImages[0].image}` : 'https://via.placeholder.com/300'}
                        alt={item.title}
                        sx={{ borderRadius: '2px 2px 0 0' }}
                      />
                      <CardContent sx={{  }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444' }}>
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#666'  , textAlign: 'justify' }}>
                          {item?.content.substring(0, 100)}...
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#666', fontStyle: 'italic', mt: 2 }}>
                          {getDollarSigns(item.price)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProfileDetailScreen;